#home{
    position: relative;
    background: url('../../assets/tokyo.png');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper{
        padding: 0;

        .copyright{
            display: none;
        }
    }
}

.app__header {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5%;
    color: #f0f0f0;
  
    .app__header-content {
      flex: 1;
      max-width: 600px;
      text-align: left;
      padding: 20px;
  
      .app__header-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
  
        .header-title {
          font-size: 2rem;
          margin-bottom: 0.5rem;
          font-weight: 300;
          color: #f0f0f0;
        }
  
        .header-name {
          font-size: 3rem;
          margin-bottom: 1rem;
          font-weight: 700;
          color: #f0f0f0;
        }
  
        .header-description {
          font-size: 1.1rem;
          line-height: 1.6;
          margin-bottom: 2rem;
          color: #f0f0f0;
        }
  
        .header-button {
          display: inline-block;
          padding: 10px 20px;
          background-color: var(--secondary-color);
          color: var(--white-color);
          text-decoration: none;
          font-size: 1rem;
          font-weight: 500;
          border-radius: 5px;
          transition: background-color 0.3s;
  
          &:hover {
            background-color: #f0f0f0;
            color: var(--secondary-color);
          }
        }
      }
    }
  
    .app__header-image {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        max-width: 100%;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      height: auto;
      padding: 20px;
  
      .app__header-content {
        max-width: 100%;
        text-align: center;
        padding: 0;
  
        .app__header-info {
          align-items: center;
  
          .header-title,
          .header-name,
          .header-description,
          .header-button {
            text-align: center;
          }
        }
      }
  
      .app__header-image {
        margin-top: 20px;
        
        img {
          max-width: 80%;
        }
      }
    }
  
    @media (max-width: 480px) {
      .app__header-content {
        .app__header-info {
          .header-title {
            font-size: 1.5rem;
          }
  
          .header-name {
            font-size: 2rem;
          }
  
          .header-description {
            font-size: 1rem;
          }
  
          .header-button {
            font-size: 0.9rem;
            padding: 8px 16px;
          }
        }
      }
  
      .app__header-image {
        img {
          max-width: 90%;
        }
      }
    }
  }  